import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import { CircleMarker, Polyline, ImageOverlay, LayerGroup } from "react-leaflet";
import { connect } from "react-redux";

import { strategySelectors } from "../Strategy/data";
import { toolColors } from "../Tools/data";
import { tools } from "../Tools/tools";

class _PathLine extends Component {
    static propTypes = {
        toolId: PropTypes.string.isRequired,
        points: PropTypes.arrayOf(
            PropTypes.shape({
                x: PropTypes.number.isRequired,
                y: PropTypes.number.isRequired,
                lineId: PropTypes.string.isRequired
            }).isRequired
        ).isRequired,
        resetOnZeroIndex: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.toolDef = tools[props.toolId];
    }

    drawEnd(toolDef, color, position) {
        if (toolDef.imgSrc) {
            const size = toolDef.radius;
            const bounds = [
                [position[0] - size, position[1] - size - (size / 10)],
                [position[0] + size, position[1] + size + (size / 10)]
            ];
            return <ImageOverlay className="toolEnd-agent" url={toolDef.imgSrc} bounds={bounds} zIndex={10} style={{ borderRadius: "5px", overflow: "none" }} />;
        } else {
            return <CircleMarker
                center={position}
                color={color}
                radius={toolDef.radius}
            />
        }

    }

    calculateLineEnd(prevPos, endPos, distanceAlongLine) {
        const lineLengthInMapUnits = Math.sqrt(Math.pow(prevPos[0] - endPos[0], 2) + Math.pow(prevPos[1] - endPos[1], 2));


        const lineLengthOnTheGround = lineLengthInMapUnits * 1;

        // calculate a point along the line based on distance to total length ratio
        const pointOnLine = [endPos[0] + (prevPos[0] - endPos[0]) * distanceAlongLine / lineLengthOnTheGround,
        endPos[1] + (prevPos[1] - endPos[1]) * distanceAlongLine / lineLengthOnTheGround];

        return pointOnLine;
    }

    drawLines() {
        const { toolId, points, resetOnZeroIndex } = this.props;

        const lines = [];
        let currentLine = 0;

        if (resetOnZeroIndex) {
            points.forEach((point, index) => {
                if (point.lineIndex === 0) {
                    if (index !== 0) {
                        currentLine++;
                    }
                    lines.push([point]);
                    return;
                }

                lines[currentLine].push(point);
            });
        } else {
            lines.push(points);
        }


        return lines.map((line, index) => {
            const sortedPoints = line.sort((a, b) => a.lineIndex - b.lineIndex);
            const latLngs = sortedPoints.map(point => [point.x, point.y]);
            const toolColor = toolColors[toolId];
            const endPos = latLngs[latLngs.length - 1];


            if (latLngs.length > 1) {
                const prevPos = latLngs[latLngs.length - 2];

                const newEnd = this.calculateLineEnd(prevPos, endPos, this.toolDef.radius);

                latLngs[latLngs.length - 1] = newEnd;
            }

            return (
                <div key={index}>
                    <LayerGroup>
                        {latLngs.length > 0 && this.drawEnd(this.toolDef, toolColor, endPos)}
                        {latLngs.length > 1 && <Polyline positions={latLngs} color={toolColor} />}
                    </LayerGroup>
                </div>
            );
        });
    }

    render() {
        return <div>{this.drawLines()}</div>;
    }
}

const mapStateToProps = (state, props) => ({
    points: strategySelectors.pointsByLineId(state, props.toolId)
});

const connected = connect(mapStateToProps)(_PathLine);

connected.propTypes = {
    toolId: PropTypes.string.isRequired,
    resetOnZeroIndex: PropTypes.bool
};

export { _PathLine, connected as PathLine };
