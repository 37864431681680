import React, { Component } from "react";
import { UtilityStats } from "./Utilitystats";
import cx from "classnames"
import { RoundStatContainer } from "./RoundStatContainer";


class PanelContainer extends Component {
    state = {
        activeTab: 1
    };

    tabClicked(tabIndex) {
        this.setState({
            activeTab: tabIndex
        });
    }

    render() {
        const tabs = [
            {
                title: "Utility",
                element: (<UtilityStats utility={this.props.insight.UtilityStats} />)
            },
            {
                title: "Rounds",
                element: (<RoundStatContainer match={this.props.match} insight={this.props.insight} />)
            }
        ];

        return (
            <div>
                <ul class="nav nav-tabs">
                    {tabs.map((tab, index) => (
                        <li key={tab.title} className="nav-item">
                            <a
                                href="#"
                                onClick={() => this.tabClicked(index)}
                                className={cx({ "nav-link": true, active: index === this.state.activeTab })}>
                                {tab.title}
                            </a>
                        </li>
                    ))}
                </ul>
                {tabs[this.state.activeTab].element}
            </div>
        )
    }
}

export {
    PanelContainer
}