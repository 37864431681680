import { combineReducers } from "redux";
import { games, getGame } from "../games";

const defaultMapState = {
    id: "",
    game: "valorant",
    map: "haven",
    version: 8,
    points: [],
    notes: ""
};

const STRATEGY_GAME_CHANGE = "ge:STRATEGY_GAME_CHANGE";
const STRATEGY_SET_ID = "ge:STRATEGY_SET_ID";
const STRATEGY_MAP_CHANGE = "ge:STRATEGY_MAP_CHANGE";
const STRATEGY_LINE_ADD = "ge:STRATEGY_LINE_ADD";
const STRATEGY_LINE_POINTS = "ge:STRATEGY_LINE_POINTS";
const STRATEGY_NOTES = "ge:STRATEGY_NOTES";
const STRATEGY_LINE_POINT_UNDO = "ge:STRATEGY_LINE_POINT_UNDO";

const strategyConstants = {
    STRATEGY_GAME_CHANGE,
    STRATEGY_MAP_CHANGE,
    STRATEGY_LINE_ADD,
    STRATEGY_LINE_POINTS,
    STRATEGY_NOTES,
    STRATEGY_LINE_POINT_UNDO
};

const strategyReducers = (function () {
    const id = (state = defaultMapState.id, action) => {
        if (action.type === STRATEGY_SET_ID) {
            return action.payload;
        }
        return state;
    };

    const game = (state = defaultMapState.game, action) => {
        if (action.type === STRATEGY_GAME_CHANGE) {
            return action.payload ? action.payload : state;
        }
        return state;
    };

    const map = (state = defaultMapState.map, action) => {
        if (action.type === STRATEGY_GAME_CHANGE && action.payload) {
            return games
                .find(game => game.id === action.payload)
                .maps.find(map => map.default).id;
        }
        if (action.type === STRATEGY_MAP_CHANGE) {
            return getGame(action.payload.gameId).maps.find(
                map => map.id === action.payload.map
            ).id;
        }

        return state;
    };

    const points = (state = defaultMapState.points, action) => {
        if (action.type === STRATEGY_LINE_ADD) {
            return [...state, action.payload];
        } else if (action.type === STRATEGY_LINE_POINTS) {
            return action.payload;
        } else if (action.type === STRATEGY_LINE_POINT_UNDO) {
            if (!!action.payload) {
                //Remove a specific one?
            }

            return [...state.slice(0, state.length - 1)];
        } else if (action.type === STRATEGY_GAME_CHANGE) {
            return defaultMapState.points;
        }

        return state;
    };

    const notes = (state = defaultMapState.notes, action) => {
        if (action.type === STRATEGY_NOTES) {
            return action.payload;
        }
        if (action.type === STRATEGY_GAME_CHANGE) {
            return defaultMapState.notes;
        }

        return state;
    };

    const version = (state = defaultMapState.version, action) => {
        return state;
    };

    return combineReducers({
        id,
        game,
        map,
        version,
        points,
        notes
    });
})();

const strategySelectors = (function () {
    const strategy = state => state.planner.strategy;
    const id = state => strategy(state).id;
    const game = state => strategy(state).game;
    const map = state => strategy(state).map;
    const points = state => strategy(state).points;
    const pointsByLineId = (state, lineId) =>
        points(state).filter(point => point.lineId === lineId);
    const notes = state => strategy(state).notes;

    return {
        id,
        strategy,
        game,
        map,
        points,
        pointsByLineId,
        notes
    };
})();

const strategyActions = (function () {
    const setId = id => ({
        type: STRATEGY_SET_ID,
        payload: id
    });

    const setGame = gameId => ({
        type: STRATEGY_GAME_CHANGE,
        payload: gameId
    });

    const setMap = (gameId, map) => ({
        type: STRATEGY_MAP_CHANGE,
        payload: {
            gameId,
            map
        }
    });

    const setPoints = point => ({
        type: STRATEGY_LINE_POINTS,
        payload: point
    });

    const setNotes = notes => ({
        type: STRATEGY_NOTES,
        payload: notes
    });

    const addPoint = (x, y, lineId, lineIndex) => ({
        type: STRATEGY_LINE_ADD,
        payload: {
            x,
            y,
            lineId,
            lineIndex
        }
    });

    const resetMap = () => ({
        type: STRATEGY_GAME_CHANGE,
        payload: defaultMapState.gameId
    });

    const undoPoint = () => ({
        type: STRATEGY_LINE_POINT_UNDO
    });

    return {
        setId,
        setGame,
        setMap,
        setPoints,
        setNotes,
        addPoint,
        resetMap,
        undoPoint
    };
})();

export {
    strategyActions,
    strategyReducers,
    strategySelectors,
    strategyConstants
};
