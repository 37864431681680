import React from 'react';
import ReactDOM from 'react-dom';
import { App } from "./App";
import { init } from "./Utils/vendor/drift";
import registerServiceWorker from './registerServiceWorker';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";

init();

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
