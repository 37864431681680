import React from "react";

const colourMapper = {
    "ExplosivesThrown": value => value > 15 ? "text-success" : "text-info",
    "TotalEnemyExplosiveDamage": value => value > 200 ? "text-success" : "text-info",
    "EnemyDamagePerExplosive": value => value > 10 ? "text-success" : "text-info",
    "TotalTeammatesExplosiveDamage": value => value > 0 ? "text-danger" : "text-success",
    "TeammatesDamagePerExplosive": value => value > 0 ? "text-danger" : "text-success",
    "FlashesThrown": value => value > 15 ? "text-success" : "text-info",
    "FlashAssists": value => value > 3 ? "text-success" : "text-info",
    "TotalEnemiesFlashed": value => value > 20 ? "text-success" : "text-info",
    "EnemiesPerFlash": value => value > 1 ? "text-success" : "text-info",
    "TotalTeammatesFlashed": value => value > 10 ? "text-danger" : "text-info",
    "TeammatesPerFlash": value => value > 1 ? "text-danger" : "text-info",
    "SmokesThrown": value => value > 15 ? "text-success" : "text-info",
    "FireThrown": value => value > 5 ? "text-success" : "text-info"
};

const titleMapper = {
    "TotalEnemyExplosiveDamage": "Total damage to enemies",
    "EnemyDamagePerExplosive": "Average damage to enemies",
    "TotalTeammatesExplosiveDamage": "Total damage to teammates",


    "FlashAssists": "Total flash assists",
    "TotalEnemiesFlashed": "Total enemies flashed",
    "EnemiesPerFlash": "Average enemies flashed",
    "TotalTeammatesFlashed": "Total teammates flashed",
    "TeammatesPerFlash": "Average teammates flashed",
}

const UtilityDisplay = ({ name, value }) => (
    <tr key={name}>
        <td>{titleMapper[name]}</td>
        <td className="pl-3"><b><span className={colourMapper[name](value)}> {value}</span></b></td>
    </tr >
);

const UtilityStats = ({ utility }) => (
    <div className="pt-3 pb-3">
        <div className="row">
            <div className="pt-3 offset-md-2 col-md-4 col-sm-6 col-12 border-right">
                <h2>
                    <i className="fas fa-bomb pr-3" />
                    {utility.ExplosivesThrown}
                </h2>
                <table>
                    <UtilityDisplay name="TotalEnemyExplosiveDamage" value={utility.TotalEnemyExplosiveDamage} />
                    <UtilityDisplay name="EnemyDamagePerExplosive" value={utility.EnemyDamagePerExplosive} />
                    <UtilityDisplay name="TotalTeammatesExplosiveDamage" value={utility.TotalTeammatesExplosiveDamage} />
                </table>
            </div>

            <div className="pt-3 col-md-4 col-sm-6 col-12">
                <h2>
                    <i className="fas fa-bomb ml-5 pr-3" />
                    {utility.FlashesThrown}
                </h2>
                <table>
                    <UtilityDisplay name="TotalEnemiesFlashed" value={utility.TotalEnemiesFlashed} />
                    <UtilityDisplay name="EnemiesPerFlash" value={utility.EnemiesPerFlash} />

                    <UtilityDisplay name="TotalTeammatesFlashed" value={utility.TotalTeammatesFlashed} />
                    <UtilityDisplay name="TeammatesPerFlash" value={utility.TeammatesPerFlash} />
                </table>
            </div>
        </div>
        <div className="row pt-3">
            <div className="pt-3 col-12 offset-md-2 col-md-4 col-sm-6 border-right">
                <h2>
                    <i className="fas fa-cloud pr-3" />
                    {utility.SmokesThrown}
                </h2>
            </div>
            <div className="pt-3 col-12 col-sm-6 col-md-4">
                <h2>
                    <i className="fas fa-fire ml-5 pr-3" />
                    {utility.FireThrown}
                </h2>
            </div>

            {/* <div className="col-6 row">
                <UtilityDisplay name="TotalEnemyExplosiveDamage" value={utility.TotalEnemyExplosiveDamage} />
                <UtilityDisplay name="EnemyDamagePerExplosive" value={utility.EnemyDamagePerExplosive} />
                <UtilityDisplay name="TotalTeammatesExplosiveDamage" value={utility.TotalTeammatesExplosiveDamage} />

                <UtilityDisplay name="FlashAssists" value={utility.FlashAssists} />
            </div>

            <div className="col-6 row">
                <UtilityDisplay name="TotalEnemiesFlashed" value={utility.TotalEnemiesFlashed} />
                <UtilityDisplay name="EnemiesPerFlash" value={utility.EnemiesPerFlash} />

                <UtilityDisplay name="TotalTeammatesFlashed" value={utility.TotalTeammatesFlashed} />
                <UtilityDisplay name="TeammatesPerFlash" value={utility.TeammatesPerFlash} />
            </div> */}
        </div>
    </div>
);

export {
    UtilityStats
}