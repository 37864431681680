import React from "react";

import { PlayerToolDrawer } from "../ToolDrawers/PlayerToolDrawer";
import { GrenadeToolDrawer } from "../ToolDrawers/GrenadeToolDrawer";
import { NotesToolDrawer } from "../ToolDrawers/NotesToolDrawer";
import { connect } from "react-redux";
import { strategyActions, strategySelectors } from "../../Strategy/data";
import { grenadeGames, gameIds } from "../../games";
import { ValorantAbilityToolDrawer } from "../ToolDrawers/valorant/ValorantAbilityToolDrawer";
import { ValorantAgentToolDrawer } from "../ToolDrawers/valorant/ValorantAgentToolDrawer";

const content = {
    titles: {
        lines: "Lines",
        misc: "Misc."
    },
    undo: "Undo"
};

const _DrawingToolbox = ({ includesGrenades, undoPoint, isValorant }) => (
    <div id="drawing-tools" className="tool-category container">
        <div className="py-2">
            <h3>{content.titles.lines}</h3>
        </div>
        {(isValorant &&
            <React.Fragment>
                <ValorantAgentToolDrawer />
                <ValorantAbilityToolDrawer />
            </React.Fragment>)
            || (
                <React.Fragment>
                    <PlayerToolDrawer />
                    {includesGrenades && <GrenadeToolDrawer />}
                </React.Fragment>
            )
        }

        <NotesToolDrawer />

        <div className="py-2">
            <h3>{content.titles.misc}</h3>
            <button
                id="tool-undo"
                onClick={undoPoint}
                className="btn btn-md btn-warning btn-block"
            >
                {content.undo}
            </button>
        </div>
    </div>
);

const mapStateToProps = state => ({
    includesGrenades:
        grenadeGames.filter(id => id === strategySelectors.game(state)).length >
        0,
    isValorant: strategySelectors.game(state) === gameIds.valorant
});

const mapDispatchToProps = dispatch => ({
    undoPoint: () => dispatch(strategyActions.undoPoint())
});

const connected = connect(mapStateToProps, mapDispatchToProps)(_DrawingToolbox);

export { _DrawingToolbox, connected as DrawingToolbox };
