import React from "react";
import { connect } from "react-redux";
import { userActions, userSelectors } from "../Auth/data";
import { Link } from "react-router-dom";
import { getGame, getMap } from "../Planner/games";

const content = {
    title: "Saved strategies",
    explainer: "None here yet, go make some strategies!"
};

class _SavedStrategies extends React.Component {
    componentDidMount() {
        this.props.getStrategies();
    }

    renderStrategy(strategy, index) {
        const game = getGame(strategy.game);
        const map = getMap(strategy.game, strategy.map);

        if (!game || !map) {
            return (
                <div className="col-4 p-1" key={strategy.id}>
                    <div className="card" style={{ backgroundColor: "black" }}>
                        <div className="card-body">
                            <h2 className="card-title">Error</h2>
                            <p className="card-text">
                                There was an error loading this strategy.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="col-4 p-1" key={strategy.id}>
                <div className="card" style={{ backgroundColor: "black" }}>
                    <div className="card-body">
                        <h2 className="card-title">
                            {game.name} - {map.name}
                        </h2>
                        <div className="card-text">
                            <h5>Notes</h5>
                            {strategy.notes && (
                                <p>{strategy.notes.substring(0, 130)}</p>
                            )}
                            {!strategy.notes && (
                                <p className="text-muted">None</p>
                            )}
                        </div>
                        <Link to={`/planner/${strategy.id}`}>
                            View{" "}
                            <span className="text-muted">
                                {strategy.id.substring(0, 5)}
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { strategies } = this.props;
        return (
            <div className="row">
                <h3 className="col-12 title my-3">{content.title}</h3>
                {strategies &&
                    strategies.length > 0 &&
                    strategies.map(this.renderStrategy)}
                {!(strategies && strategies.length > 0) && (
                    <p>{content.explainer}</p>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    strategies: userSelectors.strategies(state)
});

const mapDispatchToProps = dispatch => ({
    getStrategies: () => dispatch(userActions.getUserStrategies())
});

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(_SavedStrategies);

export { _SavedStrategies, connected as SavedStrategies };
