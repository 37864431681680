import { Auth0Lock } from "auth0-lock";
import { userActions } from "../data";
import { appActions } from "../../data";

class Auth {
    lock = new Auth0Lock(
        "v1P3KDmJ3FPWd8AZuVej6vjukkWp3VK4",
        "globalelitist.eu.auth0.com",
        {
            autoclose: true,
            auth: {
                redirectUrl: `${window.location.protocol}//${window.location.host}/callback`,
                responseType: "token",
                audience: `${window.location.protocol}//${window.location.host}`,
                params: {
                    scope: "openid profile email"
                }
            },
            theme: {
                logo: "/images/logo-stacked.png",
                primaryColor: "#ff7811"
            },
            languageDictionary: {
                title: "Global Elitist"
            }
        }
    );

    constructor() {
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.bindEvents = this.bindEvents.bind(this);
    }

    bindEvents(dispatch) {
        this.dispatch = dispatch;

        this.lock.on("hide", () => {
            this.checkSession();
        });

        this.handleAuthentication();
    }

    handleAuthentication = () => {
        try {
            return new Promise((res, rej) => {
                this.lock.on("authenticated", authResult => {
                    res(this.getUserInfo(authResult));
                });
            });
        } catch (e) {
            //noop
        }
    };

    checkSession = () => {
        this.lock.checkSession({}, (err, authResult) => {
            if (err) {
                return;
            }

            this.getUserInfo(authResult);
        });
    };

    getUserInfo = authResult => {
        new Promise((res, rej) => {
            // Use the token in authResult to getUserInfo() and save it to localStorage
            this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
                if (error) {
                    this.dispatch(appActions.finishPreload());
                    rej(error);
                    return;
                }

                localStorage.setItem("accessToken", authResult.accessToken);
                localStorage.setItem("profile", JSON.stringify(profile));

                this.setSession(authResult);

                this.dispatch(userActions.loggedIn({ ...authResult, profile }));

                this.dispatch(appActions.finishPreload());
                res();
            });
        });
    };

    login() {
        this.lock.show();
    }

    setSession(authResult) {
        // Set the time that the Access Token will expire at
        let expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
        );
        localStorage.setItem("access_token", authResult.accessToken);
        localStorage.setItem("id_token", authResult.idToken);
        localStorage.setItem("expires_at", expiresAt);
    }

    checkLoggedIn = () => {
        const accessToken = localStorage.getItem("access_token");
        const idToken = localStorage.getItem("id_token");
        const expiresAt = localStorage.getItem("expires_at");

        if (accessToken && idToken && expiresAt) {
            this.getUserInfo({
                accessToken
            });
        } else {
            this.dispatch(appActions.finishPreload());
        }
    };

    logout() {
        // Clear Access Token and ID Token from local storage
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");

        this.lock.logout({
            returnTo: `${window.location.protocol}//${window.location.host}`
        });
    }

    static isAuthenticated() {
        // Check whether the current time is past the
        // Access Token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
        return new Date().getTime() < expiresAt;
    }
}

export { Auth };
