import React from "react";
import { HltvScoreDisplay } from "../HltvScoreDisplay";

const OverallStats = ({ insight }) => {

    return (
        <div className="mb-3">
            <h5 className="mb-3">{insight.PlayerName}</h5>
            <div className="row mb-4">
                <div className="col-4">
                    <div>Kills</div>
                    <h5>{insight.TotalKills}</h5>
                </div>
                <div className="col-4">
                    <div>Deaths</div>
                    <h5>{insight.TotalDeaths}</h5>
                </div>
                <div className="col-4">
                    <div>KDR</div>
                    <h5>{Math.round((insight.TotalKills / insight.TotalDeaths) * 100) / 100}</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <HltvScoreDisplay hltvScore={insight.HltvScore} />
                </div>
                <div className="col-4">
                    <div>Source</div>
                    <div><i className="fab fa-steam-square fa-3x" /></div>
                </div>
            </div>
        </div>
    );
}

export {
    OverallStats
}