import React from "react";
import { Map, TileLayer } from "react-leaflet";
import { connect } from "react-redux";
import { CRS } from "leaflet";

import { strategySelectors } from "../../Strategy/data";
import { getMap } from "../../games";
import { toolSelectors } from "../../Tools/data";
import { mapActions } from "../data";

const _TiledMap = ({ gameId, map, handleClick, showBranding, children }) => (
    <TileLayer
        url={`/images/maps/${gameId}/${map.url}/{z}/{x}/{y}.jpg`}
        noWrap={true}
        continuousWorld={true}
        attribution={
            showBranding
                ? "Powered by <a href='https://www.globalelitist.com'>www.globalelitist.com</a>."
                : ""
        }
        bounds={[[0, 0], [-255, 255]]}
    />
);

export { _TiledMap as TiledMap };
