import axios from "axios";
import { apiEndpoint } from "../Globals";

const callProtectedApi = (method, url, accessToken, data) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`
    };
    return callUnprotectedApi(method, url, data, headers);
};

const callUnprotectedApi = (method, url, data, headers) => {
    return axios({
        method,
        url: `${apiEndpoint}/${url}`,
        data,
        headers
    });
};

export { callUnprotectedApi, callProtectedApi };
