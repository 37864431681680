import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Map } from "react-leaflet";
import { CRS } from "leaflet";

import { PathLine } from "./PathLine";
import { ImageLayerMap } from "./Maps/ImageLayerMap";
import { getMap, getGame, mapTypes } from "../games";
import { TiledMap } from "./Maps/TiledMap";
import { strategySelectors } from "../Strategy/data";
import { IconRender } from "./IconRender";
import { toolSelectors } from "../Tools/data";
import { CurrentToolHint } from "./CurrentToolHint";
import { mapActions } from "./data";
import { tools, toolIds } from "../Tools/tools";

class _Canvas extends Component {
    passChildren = (toolIds) => (
        <div>
            {toolIds.map(toolId => {
                const tool = tools[toolId];

                return (tool.line && <PathLine toolId={toolId} />) || <IconRender toolId={toolId} />;
            })}

            {this.props.toolId !== "" && (
                <CurrentToolHint currentTool={this.props.toolId} />
            )}
        </div>
    );

    // Current state render
    // Players
    // Grenade

    // Paths
    //  <PathLine toolId="player-green" />

    render() {
        const {
            gameId,
            map,
            mapType,
            showBranding,
            handleClick,
            handleMouseMove,
            toolId
        } = this.props;

        const maxBounds = [[0, 0], [...map.size]],
            center = map.size.map(size => size / 2),
            startZoom = mapType === mapTypes.static ? 0 : 2,
            minZoom = mapType === mapTypes.static ? -1 : 1,
            maxZoom = mapType === mapTypes.static ? 3 : 5,
            maxBoundsViscosity = 1;

        return (
            <div
                className={cx("map-container", {
                    toolActive: toolId !== ""
                })}
            >
                <Map
                    style={{ "backgroundColor": "#000000" }}
                    crs={CRS.Simple}
                    center={center}
                    zoom={startZoom}
                    minZoom={minZoom}
                    maxZoom={maxZoom}
                    maxBounds={maxBounds}
                    maxBoundsViscosity={maxBoundsViscosity}
                    onClick={handleClick.bind(this)}
                    onMouseMove={handleMouseMove.bind(this, toolId)}
                >
                    {mapType === mapTypes.static && (
                        <ImageLayerMap showBranding={showBranding} game={gameId} map={map} />
                    )}
                    {mapType === mapTypes.tiled && (
                        <TiledMap showBranding={showBranding} game={gameId} map={map} />
                    )}

                    {this.passChildren(toolIds)}
                </Map>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const gameId = strategySelectors.game(state);

    return {
        gameId,
        mapType: getGame(strategySelectors.game(state)).mapType,
        toolId: toolSelectors.currentTool(state),
        map: getMap(gameId, strategySelectors.map(state))
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    handleClick: event => dispatch(mapActions.handleClick(event)),
    handleMouseMove: (toolId, event) => {
        if (toolId === "") {
            return;
        }
        dispatch(mapActions.handleMouseMove(event));
    }
});

const connected = connect(mapStateToProps, mapDispatchToProps)(_Canvas);

export { _Canvas, connected as Canvas };
