import React from "react";

import { ToolButton } from "../../ToolButton";

const content = {
    mapTitle: "Abilities"
};

const ValorantAbilityToolDrawer = () => (
    <div className="tool-drawer py-2">
        <h4>{content.mapTitle}</h4>
        <div className="btn-group btn-group-lg w-100">
            <ToolButton toolName="grenade-smoke">
                <a title="Smoke">
                    Smoke
                </a>
            </ToolButton>
            <ToolButton toolName="grenade-he">
                <a title="High Explosive">
                    Grenade
                </a>
            </ToolButton>
            <ToolButton toolName="grenade-flash">
                <a title="Flash">
                    Flash
                </a>
            </ToolButton>
            <ToolButton toolName="grenade-fire">
                <a title="Fire">
                    Fire
                </a>
            </ToolButton>
            <ToolButton toolName="grenade-recon">
                <a title="Recon">
                    Recon
                </a>
            </ToolButton>
        </div>
    </div>
);

export {
    ValorantAbilityToolDrawer
};
