import { combineReducers } from "redux";
import { all, call, takeEvery } from "redux-saga/effects";

import { load } from "../Utils/vendor/drift";

const APP_LOAD = "app:LOAD";
const APP_PRE_LOAD = "app:PRE_LOAD";

//Selectors
const appSelectors = (function() {
    const app = state => state.app;
    const preloadFinished = state => app(state).preloadFinished;

    return {
        preloadFinished
    };
})();

// Action creators
const appActions = (function() {
    const finishPreload = () => ({
        type: APP_PRE_LOAD
    });

    return {
        finishPreload
    };
})();

const initialState = {
    preloadFinished: false
};

const appReducer = (function() {
    const preloadFinished = (state = initialState.preloadFinished, action) => {
        if (action.type === APP_PRE_LOAD) {
            return true;
        }

        return state;
    };

    return combineReducers({
        preloadFinished
    });
})();

function* LoadApp() {
    yield call(load);
}

function* AppSaga() {
    yield all([takeEvery(APP_LOAD, LoadApp)]);
}

export {
    APP_LOAD,
    APP_PRE_LOAD,
    AppSaga,
    appActions,
    appSelectors,
    appReducer as AppReducer
};
