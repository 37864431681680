import React from "react";

const DiscordButton = () => (
    <a href="https://discord.gg/WfcZF78" className="btn btn-outline-secondary">
        <span className="pr-2">Support</span>
        <img
            src="https://discordapp.com/assets/1c8a54f25d101bdc607cec7228247a9a.svg"
            height="35px"
            alt="discord"
        />
    </a>
);

export { DiscordButton };
