import React from "react";
import { Link } from "react-router-dom";

import { AllArticles } from "./Articles";

import "./tips.css";

const Tips = () => {

    return (
        <div className="container">
            <h1>Guides and tips</h1>
            <div className="row pt-3">
                {AllArticles.map(article => (
                    <Link to={`/tips/${article.slug}`}
                        className="article featured col-6 rounded border border-secondary align-text-bottom p-0"
                        key={article.slug}>
                        <div className="content" style={{ backgroundImage: `url(${article.imgUrl})` }}>
                            <div className="title p-3 mb-0">
                                <h5>{article.title}</h5>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div >
    )
}

export {
    Tips
}