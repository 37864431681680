import React from "react";
import {connect} from "react-redux";

import {strategyActions, strategySelectors} from "../../Strategy/data";

const content = {
    mapTitle: "Notes",
    general: "General",
    location: "Location",
    placeholder: "Blue buys 2 flashes, orange buys a smoke..."
};

const _NotesToolDrawer = ({ mapNotes, setNotes }) => (
    <div className="tool-drawer py-2">
        <h3>{content.mapTitle}</h3>
        {/*<h4 className="py2">{content.general}</h4>*/}

        <textarea className="form-control"
                  rows="10"
                  placeholder={content.placeholder}
                  onBlur={e => setNotes(e.target.value)}
                  defaultValue={mapNotes}
        />

        {/*<h4>{content.location}</h4>*/}
        {/*<div className="btn-group btn-group-justified">*/}
            {/*<ToolButton toolName="comment-box" toolColor="white">*/}
                {/*<i className="fa fa-commenting"/>*/}
            {/*</ToolButton>*/}
        {/*</div>*/}
    </div>
);

const mapStateToProps = state => ({
    mapNotes: strategySelectors.notes(state)
});

const mapDispatchToProps = dispatch => ({
    setNotes: notes => dispatch(strategyActions.setNotes(notes))
});

const connected = connect(mapStateToProps, mapDispatchToProps)(_NotesToolDrawer);

export {
    _NotesToolDrawer,
    connected as NotesToolDrawer
};
