import React, { Component } from "react";
import {userActions} from "./data";
import {connect} from "react-redux";

class _AuthCallback extends Component {
    componentDidMount() {
        this.props.handleCallback(this.props)
    }

    render() {
        const style = {};

        return (
            <div style={style}>
                <h4>Loading</h4>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    handleCallback: (props) => dispatch(userActions.handleCallback(props))
});

const  connected = connect(undefined, mapDispatchToProps)(_AuthCallback);

export {
    connected as AuthCallback
}
