import React from "react";
import { connect } from "react-redux";
import { Circle, Polyline } from "react-leaflet";

import { toolSelectors } from "../Tools/data";
import { strategySelectors } from "../Strategy/data";
import { mapSelectors } from "./data";
import { tools } from "../Tools/tools";

const CurrentToolHint = ({ currentTool, pathPoints, mouseLatLng }) => {
    const toolColor = "#CCCCCC33";

    if (!mouseLatLng || mouseLatLng.length === 0) {
        return null;
    }

    const toolDef = tools[currentTool];

    const children = [];

    children.push(
        <Circle
            key={1}
            center={mouseLatLng}
            radius={toolDef.radius}
            color={toolColor}
        />
    );

    if (toolDef.line) {
        const sortedPoints = pathPoints.sort(
            (a, b) => (a.lineIndex > b.lineIndex ? 1 : -1)
        );
        const lastPoint = sortedPoints[pathPoints.length - 1];

        if (lastPoint) {
            const drawPos = [[lastPoint.x, lastPoint.y], mouseLatLng];

            children.push(
                <Polyline key={2} positions={drawPos} color={toolColor} />
            );
        }
    }

    return children;
};

const mapStateToProps = (state, props) => ({
    pathPoints: strategySelectors.pointsByLineId(state, props.currentTool),
    mouseLatLng: mapSelectors.mouseLatLng(state)
});

const connected = connect(mapStateToProps)(CurrentToolHint);

export { connected as CurrentToolHint };
