import React from "react";
import { ShowDetails } from "./ShowDetails";
import { SavedStrategies } from "./SavedStrategies";
import { ConnectionDetails } from "./ConnectionDetails";

const content = {
    title: "Your profile"
};

const styles = {
    color: "white"
};

const UserProfile = () => (
    <div className="container" style={styles}>
        <div className="title my-3">
            <h2>{content.title}</h2>
        </div>
        <div className="row">
            <ShowDetails />
            <div className="col-sm-1"></div>
            <ConnectionDetails />
        </div>
        <SavedStrategies />
    </div>
);

export { UserProfile };
