import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { userSelectors } from "../Auth/data";
import { callProtectedApi } from "../../Utils/apiService";
import { combineReducers } from "redux";
import { init } from "../../Utils/vendor/drift";

const MATCHES_LOAD = "ge:MATCHES_LOAD";
const MATCHES_LOADED = "ge:MATCHES_LOADED";

const INSIGHTS_LOAD = "ge:INSIGHTS_LOAD";
const INSIGHTS_LOADED = "ge:INSIGHTS_LOADED";

const mapMap = {
    "de_dust2": "Dust II",
    "de_mirage": "Mirage",
    "de_cache": "Cache",
    "de_nuke": "Nuke",
    "de_overpass": "Overpass",
    "de_inferno": "Inferno"
};

const insightsActions = (() => {
    const matchesLoad = () => ({
        type: MATCHES_LOAD
    });

    const matchesLoaded = payload => ({
        type: MATCHES_LOADED,
        payload
    });

    const insightsLoaded = payload => ({
        type: INSIGHTS_LOADED,
        payload
    });

    return {
        matchesLoad,
        matchesLoaded,
        insightsLoaded
    };
})();

const insightsSelectors = (() => {
    const insights = state => state.insights;
    const matches = state => insights(state).matches;
    const matchByMatchId = (state, matchId) => matches(state).filter(ins => ins.id === matchId);
    const matchInsights = state => insights(state).insights;
    const matchInsightByMatchId = (state, matchId) => matchInsights(state) && matchInsights(state).filter(ins => ins.matchId === matchId);

    return {
        matches,
        matchByMatchId,
        matchInsights,
        matchInsightByMatchId
    };
})();

const insightsReducer = (() => {
    const initialState = {
        matches: [
            
        ],
        insights: [
        ]
    };

    const matches = (state = initialState.matches, action) => {
        if (action.type === MATCHES_LOADED) {
            return action.payload;
        }
        return state;
    };

    const insights = (state = initialState.insights, action) => {
        if (action.type === INSIGHTS_LOADED) {
            let existingData = state === null ? [] : state;
            return [...existingData, ...action.payload];
        }
        return state;
    };

    return combineReducers({
        matches,
        insights
    });
})();

function* MatchesLoad() {
    yield call(GetUserMatches);
}

function* GetUserMatches(skip = 0, take = 10) {
    const accessToken = yield select(userSelectors.accessToken);
    const response = yield call(
        callProtectedApi,
        "get",
        `insights/matches?skip=${skip}&take=${take}`,
        accessToken
    );

    yield put(insightsActions.matchesLoaded(response.data));
}

function* MatchesLoaded(action) {
    const accessToken = yield select(userSelectors.accessToken);
    let calls = [];


    for (var matchIt in action.payload) {
        calls.push(call(LoadInsight, action.payload[matchIt], accessToken));
    }

    yield all(calls);
}

function* LoadInsight(match, accessToken) {
    if (!match.map) {
        return;
    }

    const matchInsightResponse = yield call(
        callProtectedApi,
        "get",
        `insights/matches/${match.id}/insights`,
        accessToken
    );

    const matchInsights = matchInsightResponse.data.map(insight => {
        var newInsight = JSON.parse(insight.insights);
        newInsight.matchId = match.id;
        return newInsight;
    });

    yield put(insightsActions.insightsLoaded(matchInsights));
}

function* InsightsSaga() {
    yield all([
        yield takeEvery(MATCHES_LOAD, MatchesLoad),
        yield takeEvery(MATCHES_LOADED, MatchesLoaded)
    ]);
}

export { insightsActions, insightsReducer, insightsSelectors, mapMap, InsightsSaga };
