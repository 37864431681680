import "./toolbar.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";

import { strategyActions } from "../Strategy/data";
import { toolActions, toolSelectors } from "./data";

import { MapToolbox } from "./ToolBoxes/MapToolbox";
import { DrawingToolbox } from "./ToolBoxes/DrawingToolbox";
import { collaborationSelectors } from "../../../Utils/collaboration";

class _Toolbar extends Component {
    static propTypes = {
        setMap: PropTypes.func.isRequired
    };

    state = {
        activeTab: 0
    };

    categoryClicked(tabIndex) {
        this.setState({
            activeTab: tabIndex
        });
    }

    render() {
        return (
            <div className="col-md-4 col-lg-3 d-none d-md-block sidebar">
                <div className="row tool-category-headings">
                    <div
                        id="map-settings-header"
                        className={cx("tool-category-header pt-3 w-50", {
                            active: this.state.activeTab === 0
                        })}
                        onClick={this.categoryClicked.bind(this, 0)}
                        title="Map Settings"
                    >
                        <p>
                            <i className="fa fa-map fa-2x" />
                        </p>
                    </div>
                    <div
                        id="drawing-tools-header"
                        className={cx("tool-category-header w-50 pt-3", {
                            active: this.state.activeTab === 1
                        })}
                        onClick={this.categoryClicked.bind(this, 1)}
                        title="Edit strategy"
                    >
                        <p>
                            <i className="fa fa-pencil-alt fa-2x" />
                        </p>
                    </div>
                </div>
                {this.state.activeTab === 0 && <MapToolbox />}
                {this.state.activeTab === 1 && <DrawingToolbox />}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setMap: mapName => dispatch(strategyActions.setMap(mapName)),
    setTool: toolName => dispatch(toolActions.setTool(toolName))
});

const Toolbar = connect(null, mapDispatchToProps)(_Toolbar);

export { _Toolbar, Toolbar };
