import { all, put, select, takeEvery } from "redux-saga/effects";
import { toolSelectors } from "../Tools/data";
import { strategyActions, strategySelectors } from "../Strategy/data";
import { combineReducers } from "redux";
import { tools } from "../Tools/tools";

//Consts
const MAP_CLICKED = "ge:MAP_CLICKED";
const MAP_HOVER = "ge:MAP_HOVER";

// Reducers
const mapReducer = (function () {
    const mouseLatLng = (state = [0, 0], action) => {
        if (action.type == MAP_HOVER) {
            return action.payload;
        }

        return state;
    };

    return combineReducers({
        mouseLatLng
    });
})();

// Selectors
const mapSelectors = (function () {
    const map = state => state.map;
    const mouseLatLng = state => map(state).mouseLatLng;

    return {
        mouseLatLng
    };
})();

// Actions
const mapActions = (function () {
    const handleClick = event => ({
        type: MAP_CLICKED,
        payload: event.latlng
    });

    const handleMouseMove = event => ({
        type: MAP_HOVER,
        payload: event.latlng
    });

    return {
        handleClick,
        handleMouseMove
    };
})();

// Sagas
function* HandleMapClicked(action) {
    const { lat, lng } = action.payload;
    const currentTool = yield select(toolSelectors.currentTool);
    const toolDef = tools[currentTool];
    //No tool selected
    if (!currentTool) {
        return;
    }

    let lineIndex = 0;

    if (toolDef.line) {
        const points = yield select(strategySelectors.points);

        if (points.length > 0) {
            lineIndex =
                points.reduce((ans, val) => {
                    return ans.lineIndex > val.lineIndex ? ans : val;
                }).lineIndex + 1;
        }
    }

    yield put(strategyActions.addPoint(lat, lng, currentTool, lineIndex));
}

function* MapSaga() {
    yield all([takeEvery(MAP_CLICKED, HandleMapClicked)]);
}

export { MapSaga, mapReducer, mapSelectors, mapActions };
