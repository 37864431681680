import "./home.css";

import React from "react";
import {Link} from "react-router-dom";

const Home = () => (
    <div className="home-container">
        <div className="col-12 banner">
            <h1>Empower your team</h1>
            <p className="hidden-xs">Our tools give you the power to improve your game.</p>
            <div className="col-12 justify-content-center">
                <Link to="/planner/" className="btn btn-success">
                    Get planning <i className="fa fa-arrow-right"/>
                </Link>
            </div>
        </div>
        <div className="container">
            <div className="row justify-content-center pt-5">
                <div className="col-sm-4 text-center">
                    <i className="fa fa-inverse fa-bolt fa-2x"/>
                    <h2>Powerful</h2>
                    <p>With our strategy planner and advice the power is in your hands.</p>
                </div>
                <div className="col-sm-4 text-center">
                    <i className="fa fa-inverse fa-share-alt fa-2x"/>
                    <h2>Shareable</h2>
                    <p>Give strategies to your team with <b>one click</b>.</p>
                </div>
                <div className="col-sm-4 text-center">
                    <i className="fa fa-inverse fa-thumbs-up fa-2x"/>
                    <h2>Simple</h2>
                    <p><b>Simple</b> enough for your n00b friend to understand.</p>
                </div>
            </div>
            <hr />
            <div className="row justify-content-center">
                <div className="col-12 col-md-6 p-3 text-center">
                    <h1>Simple and powerful planning</h1>
                    <p>Using Global Elitist's innovative planning tool, you can create incredibly detailed strategies with your team.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 mb-2 mb-md-0">
                    <div className="branding-background p-2 text-center">
                        <Link to="/planner/" className="btn btn-md btn-block btn-success mb-2">
                            Start planning now! <i className="fa fa-arrow-right"/>
                        </Link>
                        <p>By the way, it's free!</p>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <img src="/images/planner-preview.jpg" className="planner-preview" alt="" />
                </div>
            </div>
            <div className="row mb-5">&nbsp;</div>
        </div>
    </div>
);

export {
    Home
}
