import React from "react";

import { ToolButton } from "../../ToolButton";
import { tools, toolIds } from "../../tools";

const content = {
    mapTitle: "Agents"
};

const ValorantAgentToolDrawer = () => {

    const agentTools = toolIds.filter(toolId => toolId.startsWith("agent-"));

    return (
        <div className="tool-drawer py-2">
            <h4>{content.mapTitle}</h4>
            {agentTools.map(toolId => (
                <ToolButton toolName={toolId}>
                    <img width="30" height="35" src={tools[toolId].imgSrc} alt={toolId.replace('agent-', '')} />
                </ToolButton>
            ))}
        </div>
    );
};

export {
    ValorantAgentToolDrawer
};
