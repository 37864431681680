import React, { Component } from "react";
import { userActions } from "../Auth/data";
import { connect } from "react-redux";

class _SteamCallback extends Component {
    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);

        this.props.handleCallback(params.get("token"));
    }

    render() {
        const style = {};

        return (
            <div style={style}>
                <h4>Loading</h4>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    handleCallback: props => dispatch(userActions.handleSteamCallback(props))
});

const connected = connect(undefined, mapDispatchToProps)(_SteamCallback);

export { connected as SteamCallback };
