import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import { combineReducers } from "redux";

import { Auth } from "./Util/Auth";
import { callProtectedApi } from "../../Utils/apiService";
import { init } from "../../Utils/vendor/drift";

// constants
const USER_LOGIN_START = "ge:USER_LOGIN_START";
const USER_LOGIN_SUCCESS = "ge:USER_LOGIN_SUCCESS";

const USER_LOGOUT_START = "ge:USER_LOGOUT_START";
const USER_LOGOUT_SUCCESS = "ge:USER_LOGOUT_SUCCESS";

const USER_HANDLE_CALLBACK = "ge:USER_HANDLE_CALLBACK";

const USER_UNAUTHORISED_REDIRECT = "ge:USER_UNAUTHORISED_REDIRECT";

const USER_GET_STRATEGIES_START = "ge:USER_GET_STRATEGIES_START";
const USER_GET_STRATEGIES_SUCCESS = "ge:USER_GET_STRATEGIES_SUCCESS";

const USER_GET_USERDATA_SUCCESS = "ge:USER_GET_USERDATA_SUCCESS";
const USER_GET_USERSTEAMDATA_SUCCESS = "ge:USER_GET_USERSTEAMDATA_SUCCESS";

const USER_STEAM_CALLBACK = "ge:USER_STEAM_CALLBACK";

const auth = new Auth();

// Action creators
const userActions = (function() {
    const startLogin = () => ({
        type: USER_LOGIN_START
    });

    const startLogout = () => ({
        type: USER_LOGOUT_START
    });

    const handleCallback = nextState => ({
        type: USER_HANDLE_CALLBACK,
        payload: nextState
    });

    const loggedIn = response => ({
        type: USER_LOGIN_SUCCESS,
        payload: response
    });

    const loggedOut = response => ({
        type: USER_LOGOUT_SUCCESS
    });

    const getUserStrategies = () => ({
        type: USER_GET_STRATEGIES_START
    });

    const getUserStrategiesSuccess = payload => ({
        type: USER_GET_STRATEGIES_SUCCESS,
        payload
    });

    const getUserSuccess = payload => ({
        type: USER_GET_USERDATA_SUCCESS,
        payload
    });

    const getUserSteamDataSuccess = payload => ({
        type: USER_GET_USERSTEAMDATA_SUCCESS,
        payload
    });

    const handleSteamCallback = payload => ({
        type: USER_STEAM_CALLBACK,
        payload
    });

    return {
        loggedIn,
        loggedOut,
        handleCallback,
        startLogin,
        startLogout,
        getUserStrategies,
        getUserStrategiesSuccess,
        getUserSuccess,
        getUserSteamDataSuccess,
        handleSteamCallback
    };
})();

// Action Selectors
const userSelectors = (function() {
    const auth = state => state.user;
    const isAuthenticated = state => auth(state).isAuthenticated;
    const profile = state => auth(state).profile;
    const accessToken = state => auth(state).accessToken;
    const user = state => auth(state).user;
    const steamData = state => auth(state).steamData;

    const strategies = state => auth(state).strategies;

    return {
        accessToken,
        isAuthenticated,
        profile,
        strategies,
        user,
        steamData
    };
})();

const initialState = {
    isAuthenticated: false,
    accessToken: null,
    profile: {
        nickname: "Test",
        picture:
            "https://www.gravatar.com/avatar/00000000000000000000000000000000"
    },
    strategies: [],
    user: {
        id: null,
        name: null
    },
    steamData: {
        steamId: null,
        steamAccessKey: null,
        csgoShareCode: null
    }
};

const userReducer = (function() {
    const isAuthenticated = (state = initialState.isAuthenticated, action) => {
        if (action.type === USER_LOGIN_SUCCESS) {
            return true;
        } else if (action.type === USER_LOGOUT_SUCCESS) {
            return false;
        }
        return state;
    };

    const user = (state = initialState.user, action) => {
        if (action.type === USER_GET_USERDATA_SUCCESS) {
            return action.payload;
        }
        if (action.type === USER_LOGOUT_SUCCESS) {
            return initialState.user;
        }

        return state;
    };

    const steamData = (state = initialState.steamData, action) => {
        if (action.type === USER_GET_USERSTEAMDATA_SUCCESS) {
            return action.payload;
        }
        return state;
    };

    const profile = (state = initialState.profile, action) => {
        if (action.type === USER_LOGIN_SUCCESS) {
            return {
                nickname: action.payload.profile.nickname,
                picture: action.payload.profile.picture
            };
        } else if (action.type === USER_LOGOUT_SUCCESS) {
            return initialState.profile;
        }
        return state;
    };

    const accessToken = (state = initialState.accessToken, action) => {
        if (action.type === USER_LOGIN_SUCCESS) {
            return action.payload.accessToken;
        } else if (action.type === USER_LOGOUT_SUCCESS) {
            return initialState.accessToken;
        }
        return state;
    };

    const strategies = (state = initialState.strategies, action) => {
        if (action.type === USER_GET_STRATEGIES_SUCCESS) {
            return action.payload;
        }
        return state;
    };

    return combineReducers({
        accessToken,
        isAuthenticated,
        profile,
        strategies,
        user,
        steamData
    });
})();

//Sagas
function* GetStrategies() {
    const accessToken = yield select(userSelectors.accessToken);
    try {
        const response = yield call(
            callProtectedApi,
            "get",
            "strategy",
            accessToken
        );
        yield put(userActions.getUserStrategiesSuccess(response.data));
    } catch (e) {
        //noop
    }
}

function* GetUser() {
    const accessToken = yield select(userSelectors.accessToken);
    const response = yield call(
        callProtectedApi,
        "get",
        `user/current`,
        accessToken
    );

    yield put(userActions.getUserSuccess(response.data));

    yield call(UpdateUser);
    yield call(GetUserSteamData);
}

function* GetUserSteamData() {
    const accessToken = yield select(userSelectors.accessToken);
    const response = yield call(
        callProtectedApi,
        "get",
        `user/current/steamData`,
        accessToken
    );

    yield put(userActions.getUserSteamDataSuccess(response.data));
}

function* UpdateUser() {
    const accessToken = yield select(userSelectors.accessToken);
    var user = yield select(userSelectors.user);
    const profile = yield select(userSelectors.profile);

    if (
        user.name === initialState.user.name &&
        profile.nickname !== initialState.profile.nickname
    ) {
        user.name = profile.nickname;
        try {
            const updateResponse = yield call(
                callProtectedApi,
                "post",
                `user/${user.id}`,
                accessToken,
                user
            );

            yield put(userActions.getUserSuccess(updateResponse.data));
        } catch (e) {
            //noop
        }
    }
}

function* UnauthorisedRedirect() {
    yield put(push("/"));
}

function* HandleCallback(action) {
    if (
        action.payload &&
        /access_token|id_token|error/.test(action.payload.location.hash)
    ) {
        try {
            yield call(auth.handleAuthentication);
        } catch (e) {
            console.log(`Auth error: ${e}`);
        }
    }

    yield put(push("/"));
}

function* HandleSteamCallback(action) {
    console.log(action);

    const accessToken = yield select(userSelectors.accessToken);
    yield call(
        callProtectedApi,
        "post",
        `steam/claim?token=${action.payload}`,
        accessToken
    );
    yield call(GetUser);
    yield put(push("/user"));
}

function* HandleLogin() {
    yield call(auth.login);
}

function* HandleLogout() {
    yield call(auth.logout);
    yield put(userActions.loggedOut());
    yield put(push("/"));
}

function* SetupAuth(dispatch) {
    yield call(auth.bindEvents, dispatch);
    yield call(auth.checkLoggedIn);
}

function* AuthSaga(dispatch) {
    yield all([
        //Auth
        yield SetupAuth(dispatch),
        yield takeEvery(USER_UNAUTHORISED_REDIRECT, UnauthorisedRedirect),
        yield takeEvery(USER_LOGIN_START, HandleLogin),
        yield takeEvery(USER_LOGOUT_START, HandleLogout),
        yield takeEvery(USER_HANDLE_CALLBACK, HandleCallback),
        yield takeEvery(USER_LOGIN_SUCCESS, GetUser),
        yield takeEvery(USER_STEAM_CALLBACK, HandleSteamCallback),

        //User stuff
        yield takeEvery(USER_GET_STRATEGIES_START, GetStrategies)
    ]);
}

export { userSelectors, userActions, userReducer as UserReducer, AuthSaga };
