import "./userbar.css";

import React from "react";
import { connect } from "react-redux";

import { userActions, userSelectors } from "../data";
import { Link } from "react-router-dom";

const content = {
    signin: "Register/Sign in",
    signout: "Sign out"
};

const _UserBar = ({ isAuthenticated, profile, user, login, logout }) => (
    <div className="nav navbar-nav userBar">
        {!isAuthenticated && (
            <form className="form-inline">
                <button
                    className="btn btn-outline-success"
                    type="button"
                    onClick={login.bind(this)}
                >
                    {content.signin}
                </button>
            </form>
        )}
        {isAuthenticated && (
            <form className="form-inline">
                <Link to="/user" className="p-0">
                    <div className="image-container">
                        <img
                            src={profile.picture}
                            alt="How the world sees you"
                            className="mx-2 rounded"
                        />
                    </div>
                    <span className="mx-2 nickname">{user.name}</span>
                </Link>
                <button
                    className="mx-2 ml-4 btn btn-sm btn-outline-secondary"
                    type="button"
                    onClick={logout.bind(this)}
                >
                    {content.signout}
                </button>
            </form>
        )}
    </div>
);

const mapStateToProps = state => ({
    isAuthenticated: userSelectors.isAuthenticated(state),
    profile: userSelectors.profile(state),
    user: userSelectors.user(state)
});

const mapDispatchToProps = dispatch => ({
    login: () => dispatch(userActions.startLogin()),
    logout: () => dispatch(userActions.startLogout())
});

const connected = connect(mapStateToProps, mapDispatchToProps)(_UserBar);

export { connected as UserBar };
