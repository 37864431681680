import "./planner.css";

import React, { Component } from 'react';
import {connect} from "react-redux";

import { Toolbar } from "./Tools/index";
import { Map } from "./Map/index";
import { plannerActions } from "./data";

class _Planner extends Component {
    componentDidMount() {
        this.props.plannerLoad();
    }
    render() {
        return (
            <div className="container-fluid planner-container">
                <div className="row">
                    <Toolbar />
                    <Map />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    plannerLoad: () => dispatch(plannerActions.plannerLoad(props.match.params.strategyId))
});

const connected = connect(null, mapDispatchToProps)(_Planner);

export {
    _Planner,
    connected as Planner
};
