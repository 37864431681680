import React from "react";
import { ValorantCompositionGuide } from "./Valorant/ValorantCompositionGuide";

const categories = {
    valorant: "Valorant",
    csgo: "CS:GO"
};

const AllArticles = [
    {
        category: categories.valorant,
        slug: "valorant-composition",
        article: <ValorantCompositionGuide />,
        title: "Valorant Composition Guide",
        imgUrl: "/images/articles/valorant/key_art.jpg"
    }
];

export { AllArticles }