const tools = {
    "player-blue": {
        line: true,
        radius: 5
    },
    "player-yellow": {
        line: true,
        radius: 3
    },
    "player-purple": {
        line: true,
        radius: 3
    },
    "player-green": {
        line: true,
        radius: 3
    },
    "player-orange": {
        line: true,
        radius: 3
    },
    "grenade-smoke": {
        line: false,
        radius: 20
    },
    "grenade-flash": {
        line: false,
        radius: 20
    },
    "grenade-he": {
        line: false,
        radius: 20
    },
    "grenade-fire": {
        line: false,
        radius: 20
    },
    "grenade-decoy": {
        line: false,
        radius: 20
    },
    "grenade-recon": {
        line: false,
        radius: 235
    },


    "agent-brimstone": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/brimstone.png"
    },
    "agent-breach": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/breach.png"
    },
    "agent-cypher": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/cypher.png"
    },
    "agent-jett": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/jett.png"
    },
    "agent-omen": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/omen.png"
    },
    "agent-phoenix": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/phoenix.png"
    },
    "agent-sage": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/sage.png"
    },
    "agent-sova": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/sova.png"
    },
    "agent-raze": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/raze.png"
    },
    "agent-viper": {
        line: true,
        radius: 15,
        imgSrc: "/images/tools/valorant-agents/viper.png"
    }
};

const toolIds = Object.keys(tools);

export { tools, toolIds };
