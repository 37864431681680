import React from "react";
import { Map } from "react-leaflet";
import { CRS } from "leaflet";


import { ImageLayerMap } from "../../Planner/Map/Maps/ImageLayerMap";

const RoundStats = ({ map, roundStats }) => {
    const maxBounds = [[0, 0], [...map.size]],
        center = map.size.map(size => size / 2),
        startZoom = -1,
        minZoom = -1,
        maxZoom = 1,
        maxBoundsViscosity = 1;

    return (
        <div>
            <div>
                <Map
                    style={{ "background-color": "#000000", "height": "600px" }}
                    crs={CRS.Simple}
                    center={center}
                    zoom={startZoom}
                    minZoom={minZoom}
                    maxZoom={maxZoom}
                    maxBounds={maxBounds}
                    maxBoundsViscosity={maxBoundsViscosity}
                >
                    <ImageLayerMap showBranding={false} game={"csgo"} map={map} />

                </Map>
            </div>
        </div>
    );
}

export {
    RoundStats
}