import React from "react";

import {ToolButton} from "../ToolButton";

const content = {
    mapTitle: "Players"
};

const PlayerToolDrawer = () => (
    <div className="tool-drawer py-2">
        <h4>{content.mapTitle}</h4>
        <div className="btn-group btn-coloured btn-group-lg w-100" role="group">
            <ToolButton toolName="player-blue">
                1
            </ToolButton>
            <ToolButton toolName="player-green">
                2
            </ToolButton>
            <ToolButton toolName="player-orange">
                3
            </ToolButton>
            <ToolButton toolName="player-yellow">
                4
            </ToolButton>
            <ToolButton toolName="player-purple">
                5
            </ToolButton>
        </div>
    </div>
);

export {
    PlayerToolDrawer
};
