import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import createHistory from "history/createBrowserHistory";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { reducer as formReducer } from "redux-form";

import { UserReducer, AuthSaga } from "./App/Auth/data";
import { PlannerReducer, PlannerSaga } from "./App/Planner/data";
import { mapReducer as MapReducer, MapSaga } from "./App/Planner/Map/data";
import { AppReducer, AppSaga } from "./App/data";
import { InsightsSaga, insightsReducer } from "./App/Insights/data";
// import { CollaborationSaga, collaborationReducer } from "./Utils/collaboration";

const history = createHistory();

const reducers = combineReducers({
    app: AppReducer,
    user: UserReducer,
    map: MapReducer,
    planner: PlannerReducer,
    router: routerReducer,
    form: formReducer,
    insights: insightsReducer
});

const sagaMiddleware = createSagaMiddleware();
const historyMiddleware = routerMiddleware(history);

const store = createStore(
    reducers,
    applyMiddleware(sagaMiddleware, historyMiddleware)
);

function* rootSagas() {
    yield all([
        PlannerSaga(),
        MapSaga(),
        AuthSaga(store.dispatch),
        AppSaga(),
        InsightsSaga()
    ]);
}

sagaMiddleware.run(rootSagas);

export { store, history };
