const mapTypes = {
    tiled: "tiled",
    static: "static"
};

const gameIds = {
    csgo: "csgo",
    pubg: "pubg",
    apex: "apexlegends",
    dota2: "dota2",
    lol: "lol",
    valorant: "valorant"
};

const grenadeGames = [gameIds.csgo, gameIds.apex];

const games = [
    {
        id: gameIds.csgo,
        name: "CS:GO",
        mapType: mapTypes.static,
        maps: [
            {
                id: "de_dust2",
                url: "de_dust2.png",
                name: "Dust2",
                size: [1024, 1024],
                default: true
            },
            {
                id: "de_cache",
                url: "de_cache.png",
                name: "Cache",
                size: [1024, 1024]
            },
            {
                id: "de_cbble",
                url: "de_cbble.png",
                name: "Cobblestone",
                size: [1024, 1024]
            },
            {
                id: "de_inferno",
                url: "de_inferno.png",
                name: "Inferno",
                size: [1024, 1024]
            },
            {
                id: "de_mirage",
                url: "de_mirage.png",
                name: "Mirage",
                size: [1024, 1024]
            },
            { id: "de_nuke", url: "de_nuke", name: "Nuke", size: [1024, 1024] },
            {
                id: "de_overpass",
                url: "de_overpass.png",
                name: "Overpass",
                size: [1024, 1024]
            },
            {
                id: "de_train",
                url: "de_train.png",
                name: "Train",
                size: [1024, 1024]
            }
        ]
    },
    {
        id: gameIds.valorant,
        name: "VALORANT",
        mapType: mapTypes.static,
        maps: [
            {
                id: "haven",
                url: "haven.svg",
                name: "Haven",
                size: [1024, 1024],
                default: true
            },
            {
                id: "bind",
                url: "bind.svg",
                name: "Bind",
                size: [1024, 1024],
            },
            {
                id: "split",
                url: "split.svg",
                name: "Split",
                size: [1024, 1024],
            }
        ]
    },
    {
        id: gameIds.apex,
        name: "Apex Legends",
        mapType: mapTypes.static,
        maps: [
            {
                id: "kings-canyon",
                url: "kings-canyon",
                name: "King's Canyon",
                size: [1015, 1015],
                default: false
            },
            {
                id: "worlds-edge",
                url: "worlds-edge",
                name: "World's Edge",
                size: [1440, 1440],
                default: true
            }
        ]
    },
    {
        id: gameIds.dota2,
        name: "DotA 2",
        mapType: mapTypes.static,
        maps: [
            {
                id: "map",
                url: "map.png",
                name: "DotA 2",
                size: [1140, 1101],
                default: true
            }
        ]
    },
    {
        id: gameIds.lol,
        name: "League of Legends",
        mapType: mapTypes.static,
        maps: [
            {
                id: "summoners-rift",
                url: "summoners-rift.png",
                name: "Summoner's Rift",
                size: [2000, 1833],
                default: true
            }
        ]
    }
];

const getGame = gameId => games.find(game => game.id === gameId);
const getMap = (gameId, mapId) =>
    getGame(gameId).maps.find(map => map.id === mapId);

export { mapTypes, gameIds, games, getGame, getMap, grenadeGames };
