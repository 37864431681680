import React from "react";
import { connect } from "react-redux";
import { userSelectors } from "../Auth/data";
import { apiEndpoint } from "../../Globals";


const returnUrl = `${apiEndpoint}/steam/callback`;
const realmUrl = window.location.host;
const steamLoginUrl = `https://steamcommunity.com/openid/login?openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.ns.sreg=http://openid.net/extensions/sreg/1.1&openid.sreg.optional=nickname,email,fullname,dob,gender,postcode,country,language,timezone&openid.ns.ax=http://openid.net/srv/ax/1.0&openid.ax.mode=fetch_request&openid.ax.type.fullname=http://axschema.org/namePerson&openid.ax.type.firstname=http://axschema.org/namePerson/first&openid.ax.type.lastname=http://axschema.org/namePerson/last&openid.ax.type.email=http://axschema.org/contact/email&openid.ax.required=fullname,firstname,lastname,email&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.return_to=${returnUrl}&openid.realm=${realmUrl}`;

const _ConnectionDetails = ({ steamData }) => (
    <div
        className="card col-xs-12 col-sm-3"
        style={{ backgroundColor: "black" }}
    >
        <h3>Connection Details</h3>
        {/* <p>To give us access to your matches, we need the following info;</p> */}
        <div className="card-body">
            {(!steamData.steamId && (
                <React.Fragment>
                    {/* <h4>Authenticate your steam profile:</h4>
                    <a href={steamLoginUrl}>
                        <img
                            src="https://steamcommunity-a.akamaihd.net/public/images/signinthroughsteam/sits_01.png"
                            alt="authenticate with steam"
                        />
                    </a> */}

                </React.Fragment>
            )) || (
                    <React.Fragment>
                        <p>
                            <span>
                                <b>Steam Id:</b>
                            </span>
                            <br />
                            <span>{steamData.steamId}</span>
                        </p>
                    </React.Fragment>
                )}
            {steamData.steamAccessKey && (
                <React.Fragment>
                    <p>
                        <span>
                            <b>Steam Auth Key:</b>
                        </span>
                        <br />
                        <span>{steamData.steamAccessKey}</span>
                    </p>
                </React.Fragment>
            )}

            {steamData.csgoShareCode && (
                <React.Fragment>
                    <p>
                        <span>
                            <b>Oldest CS:GO Sharecode:</b>
                        </span>
                        <br />
                        <span>{steamData.csgoShareCode}</span>
                    </p>
                </React.Fragment>
            )}
        </div>
    </div>
);

const mapStateToProps = state => ({
    user: userSelectors.user(state),
    steamData: userSelectors.steamData(state)
});

const connected = connect(mapStateToProps)(_ConnectionDetails);

export { _ConnectionDetails, connected as ConnectionDetails };
