import React from "react";
import {plannerActions} from "../Planner/data";
import {connect} from "react-redux";
import {Canvas} from "../Planner/Map/MapDisplay";

class _Viewer extends React.Component {
    componentDidMount() {
        this.props.viewerLoad();
    }

    render() {
        return (
            <div className="container-fluid planner-container">
                <div className="row">
                    <div id="map" className="col-12 p-0">
                        <Canvas showBranding={true} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    viewerLoad: () => dispatch(plannerActions.viewerLoad(props.match.params.strategyId))
});

const connected = connect(undefined, mapDispatchToProps)(_Viewer);

export {
    connected as Viewer
}
