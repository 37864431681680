import React, { Component } from "react";
import { UtilityStats } from "./Utilitystats";
import cx from "classnames"
import { RoundStats } from "./RoundStat";
import { connect } from "react-redux";
import { getMap } from "../../Planner/games";


class _RoundStatContainer extends Component {
    state = {
        currentRound: 0
    };

    selectRound(roundId) {
        //let roundId = parseInt(event.target.value) - 1;
        const totalRounds = this.props.insight.PlayerRoundStats.length - 2;

        if (roundId > totalRounds) {
            roundId = totalRounds;
        }

        if (roundId < 0) {
            roundId = 0;
        }

        this.setState({
            currentRound: roundId
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col order-second" style={{ "height": "600px", "overflow-y": "scroll" }}>
                    <table class="table table-dark" >
                        <tbody>
                            {this.props.insight.PlayerRoundStats.map((round, index) => (
                                <tr className={cx({ "bg-success": round.Death === null, "bg-danger": round.Death })} onClick={this.selectRound.bind(this, index)}>
                                    <td>
                                        {this.state.currentRound === index && <i class="fas fa-chevron-right" />}
                                        <span class="pl-1">
                                            # {index} | Kills: {round.Kills.length} {round.Death ? "(died)" : ""}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-9 order-first">
                    <RoundStats map={this.props.map} roundStats={this.props.insight.PlayerRoundStats[this.state.currentRound]} />
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state, props) => ({
    map: getMap("csgo", props.match.map)
});

const connected = connect(mapStateToProps)(_RoundStatContainer);

export {
    _RoundStatContainer,
    connected as RoundStatContainer
}