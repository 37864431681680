import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { appSelectors } from "./data";

const _AppLoading = ({ ready, children, ...rest }) => (
    <React.Fragment>
        {ready && children}
        {!ready && (
            <div style={{ margin: "auto" }}>
                <div style={{ width: "320px", textAlign: "center" }}>
                    <h4 className="pb-5">Loading, please wait...</h4>
                    <div className="spinner-border" role="status">
                    </div>
                </div>
            </div>
        )}
    </React.Fragment>
);

_AppLoading.propTypes = {
    ready: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired
};

const mapStateToProps = (state, props) => ({
    ready: appSelectors.preloadFinished(state)
});

const connected = connect(mapStateToProps)(_AppLoading);

export { _AppLoading, connected as AppLoading };
