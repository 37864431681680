import React from "react";
import { ImageOverlay } from "react-leaflet";
import { gameIds } from "../../games";

const csgoBranding = "Map provided by <a target='__blank' href='http://www.simpleradar.com/'>SimpleRadar</a>";
const valorantBranding = "Map provided by <a target='__blank' href='https://blitz.gg/'>Blitz.gg</a>";

const branding = (game) => {
    switch (game) {
        case gameIds.csgo:
            return csgoBranding
        case gameIds.valorant:
            return valorantBranding;
        default:
            return "";
    }
}

const _ImageLayerMap = ({ game, map }) => (
    <ImageOverlay
        attribution={null}
        url={`/images/maps/${game}/${map.url}`}
        bounds={[[0, 0], [...map.size]]}
    />
);


export { _ImageLayerMap as ImageLayerMap };
