import React from "react";
import { connect } from "react-redux";
import { userSelectors } from "../Auth/data";

const _ShowDetails = ({ profile, user }) => (
    <div
        className="card col-xs-12 col-sm-3"
        style={{ backgroundColor: "black" }}
    >
        <img
            className="card-img-top py-2"
            src={profile.picture}
            alt="How the world sees you"
        />
        <div className="card-body">
            <h5 className="card-title">{user.name}</h5>
            <p className="card-text">
                <span className="emphasis px-1">Rank:</span>
                <span>0</span>
                <br />
            </p>
        </div>
    </div>
);

const mapStateToProps = state => ({
    profile: userSelectors.profile(state),

    user: userSelectors.user(state)
});

const mapDispatchToProps = dispatch => ({});

const connected = connect(mapStateToProps, mapDispatchToProps)(_ShowDetails);

export { _ShowDetails, connected as ShowDetails };
