import React from "react";
import { NavLink } from "react-router-dom";
import { UserBar } from "../Auth/UserBar";
import { connect } from "react-redux";
import { APP_LOAD } from "../data";
import { DiscordButton } from "./Buttons/DiscordButton";
import { FacebookButton } from "./Buttons/Facebook";
import { TwitterButton } from "./Buttons/Twitter";

class Header extends React.Component {

    componentDidMount() {
        this.props.loadApp();
    }

    render() {

        return (
            <nav className="navbar navbar-expand-sm">
                <div className="navbar-brand">
                    <NavLink to="/" exact={true}>
                        <img
                            alt="The globalelitist logo"
                            src="/images/logo-banner-small.png"
                        />
                    </NavLink>
                </div>
                <div id="navbar" className="navbar-collapse collapse">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item" >
                            <NavLink
                                activeClassName="active"
                                to="/"
                                exact={true}
                                strict={true}
                                className="nav-link"
                            >
                                Home
                        </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                activeClassName="active"
                                to="/planner/"
                                className="nav-link"
                            >
                                Planner
                        </NavLink>
                        </li>
                    </ul>
                    <div className="navbar-right">
                        <div className="navbar-rightSocial">
                            <TwitterButton />
                            <FacebookButton />
                            <DiscordButton />
                        </div>
                        <UserBar />
                    </div>
                </div>
            </nav>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadApp: () => dispatch({ type: APP_LOAD })
});

const connected = connect(null, mapDispatchToProps)(Header);

export { connected as Header };
