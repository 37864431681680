import React from "react";
import { connect } from "react-redux";

import { InsightsRow } from "./InsightsRow";
import { insightsSelectors } from "./data";

const _InsightsContainer = ({ matches }) => {
    return (
        <div className="col-12">
            <div className="row mt-5 match-container">
                {(matches && matches.map(match => (
                    <InsightsRow
                        key={match.id}
                        match={match}
                    />
                ))) ||
                    (
                        <p className="text-center w-100">
                            It looks like you've not hooked up your account yet (or no matches have been checked)
                        </p>
                    )}
            </div>
            <p className="text-center text-muted">If you've got more than 10 matches played, you'll be able to get to them all soon!</p>
        </div>
    );
};

const mapStateToProps = state => ({
    matches: insightsSelectors.matches(state)
});

const connected = connect(mapStateToProps)(_InsightsContainer);

export { _InsightsContainer, connected as InsightsContainer };
