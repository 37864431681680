import React from "react";
import { Link } from "react-router-dom";

import { AllArticles } from "./Articles";

const ArticleDisplay = ({ match }) => {
    const articleId = match.params.articleSlug;

    const articleMap = AllArticles.filter(article => article.slug === articleId);
    if (articleMap.length === 0) {
        //route back to tips?
    }

    const article = articleMap[0];

    return (
        <div className="container">
            <Link to="/tips/">&lt; Back to tips</Link>

            <h1>{article.title}</h1>
            <div className="article-header">
                <img src={article.imgUrl} width="100%" />
            </div>
            {article.article}
        </div>
    );

}

export { ArticleDisplay }