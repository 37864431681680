import React, { Component } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import { strategySelectors } from "../Strategy/data";
import { Canvas } from "./MapDisplay";


class _Map extends Component {
    static propTypes = {
        mapLevel: PropTypes.string.isRequired
    };

    render() {
		return (
			<div id="map" className="col-sm-12 col-md-8 col-lg-9 p-0">
                <Canvas />
		    </div>
	    );
	}
}

const mapStateToProps = state => ({
    mapLevel: strategySelectors.map(state)
});

const connected = connect(mapStateToProps)(_Map);

export {
    _Map,
    connected as Map
};
