import React from "react";

const HltvScoreDisplay = ({ hltvScore }) => (
    <React.Fragment>
        HLTV Score: <br />
        <h5 className={(hltvScore > 1 ? "text-success" : "text-warning")}>{hltvScore}<sup>
            <small>
                <a href="https://www.hltv.org/news/4094/what-is-that-rating-thing-in-stats">(?)</a>
            </small>
        </sup></h5>
    </React.Fragment>
)

export {
    HltvScoreDisplay
}