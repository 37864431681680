import "../site.css";

import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { Route, Switch } from "react-router-dom";
import Notifications from "react-notify-toast";

import { AppLoading } from "./AppLoading";
import { store, history } from "../store";
import { Header } from "./Shared/Header";
import { Viewer } from "./Viewer";
import { AppRouter } from "./AppRouter";

const App = () => (
    <Provider store={store}>
        <AppLoading>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route
                        exact
                        path="/viewer/:strategyId"
                        component={Viewer}
                    />
                    <Route>
                        <div className="app">
                            <Header />
                            <Notifications />
                            <AppRouter />
                        </div>
                    </Route>
                </Switch>
            </ConnectedRouter>
        </AppLoading>
    </Provider>
);

export { App };
