import React from "react";
import { connect } from "react-redux";

import { userSelectors } from "../../../Auth/data";
import { plannerActions } from "../../data";

const content = {
    title: "Share",
    explainer: "Create a free account to start a playbook!",
    save: "Save strategy"
};

const _UserMapOptionsDrawer = ({ isAuthenticated, saveStrategy }) => (
    <div className="py-2">
        <h3>{content.title}</h3>
        <button
            onClick={saveStrategy}
            className="btn btn-lg btn-block btn-success"
        >
            {content.save}
        </button>
        {!isAuthenticated && (
            <p className="text-center text-muted">{content.explainer}</p>
        )}
    </div>
);

const mapStateToProps = state => ({
    isAuthenticated: userSelectors.isAuthenticated(state)
});

const mapDispatchToProps = dispatch => ({
    saveStrategy: () => dispatch(plannerActions.saveStrategy())
});

const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(_UserMapOptionsDrawer);

export { _UserMapOptionsDrawer, connected as UserMapOptionsDrawer };
