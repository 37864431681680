import React from "react";
const ReactMarkdown = require("react-markdown");


const article = `
One of the most powerful advantages you can possibly get in VALORANT is the combination of great teamplay and abilities.

A strong team composition isn't new if you've played other competitive games like Overwatch, League of Legends or Dota 2. The structure of your team affects how you play off one another and how you can approach your objectives.

After the recent few tournaments and show matches that have taken place, a clear and powerful structure has started to emerge:

**Utility is king,**

**Information is queen**

This concept is pretty simple if you come from Counter-Strike, however people from other games **may** think that more damage = more kills. This is actually rarely the case.

I'll dig into the individual benefits of each agent in separate posts, but to give an overview, here are the categories of agents as I see it

## Utility agents

To start off, there are a few agents that fall purely into the Utility category. Those are;

- Sage
- Brimstone
- Cypher
- Viper
- Breach
- Phoenix
- Omen

These agents provide amazing benefits to the whole team just by being alive. Their ability to carve up the map and help with either pushes or defending allows you to isolate individual enemies or force the other team to make mistakes.

## Information agents

In the information category, we have;

- Sova
- Cypher (again)
- Phoenix

Information agents are key to understanding where your enemies are and how to dish out your utility to get the best possible coverage.

## Damage agents

To finish off, in the damage category are;

- Sova
- Raze
- Viper
- Jett

All of these agents are somewhat geared up to deal damage or to use damage as their primary ability. Interestingly Jett fits into this category mostly due to her abilities combining with aggressive plays, however her abilities themselves are mostly quite innocent.

## My ideal team composition

So we've seen the categories, what team do I recommend?

I'd recommend taking as many utility agents as possible, with some information agents mixed in. If I had 5 players that played their characters extremely well, my dream list would look something like;

- Sage (100% pick, pretty much)
    - Top tier defensive utility, good offensive capabilities
- Brimstone/Viper/Omen
    - Primary smokes/utility
- Cypher/Viper
    - Secondary utility and being able to stop/be aware of flanking players
- Breach/Phoenix/Omen
    - Tertiary utility, information and mind games
- Sova/Phoenix
    - If played well, provides amazing information however can be somewhat random

You'll notice that Raze/Jett don't make my dream list. 

Jett's abilities don't actually give that much utility to the team, only to the individual playing her, which makes her kit way less desirable. If you were to have a strong, quick thinking entry-fragger then Jett may be quite useful, but if you're a strong team I think you'd be better with a stronger utility player.

Raze is often called broken and has annoyed so many people in the matchmaking at the moment. However, I think she's a solo matchmaking hero. She's a great character for going for collateral kills in your ladder games, but in a competitive scenario I think she'll be countered so easily that she may only find one kill maximum with her grenades per round. Her robot is easily denied and her satchel charges are mostly used to boost her around, leaving her overall kit pretty undesirable in competitive play.
`;

const CompositionGuide = () => (
    <ReactMarkdown source={article} />
);

export {
    CompositionGuide as ValorantCompositionGuide
};