import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Circle } from "react-leaflet";

import { strategySelectors } from "../Strategy/data";
import { toolColors } from "../Tools/data";
import { tools } from "../Tools/tools";

const _IconRender = ({ points, toolId }) => {
    const toolDef = tools[toolId];

    return (
        <div className={toolId} key={toolId}>
            {points.map((point, index) => (
                <Circle
                    key={index}
                    center={[point.x, point.y]}
                    radius={toolDef.radius}
                    color={toolColors[toolId]}
                />
            ))}
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    points: strategySelectors.pointsByLineId(state, props.toolId)
});

const connected = connect(mapStateToProps)(_IconRender);

connected.propTypes = {
    toolId: PropTypes.string.isRequired
};
export { connected as IconRender };
