import React from "react";
import { Route, Switch } from "react-router-dom";

import { Planner } from "./Planner";
import { Home } from "./Home";
import { AuthCallback } from "./Auth/AuthCallback";
import { userSelectors } from "./Auth/data";
import { UserProfile } from "./User";
import { PrivateRoute } from "../PrivateRoute";
import { SteamCallback } from "./User/SteamCallback";
import { Insights } from "./Insights";
import { InsightMatch } from "./Insights/Match";
import { Tips } from "./Tips";
import { NotFound } from "./Errors";
import { ArticleDisplay } from "./Tips/ArticleDisplay";

const AppRouter = () => (
    <Switch>
        <Route exact path="/" component={Home} />

        <Route path="/planner/" exact component={Planner} />
        <Route
            path="/planner/:strategyId"
            component={Planner}
        />

        <Route
            path="/tips"
            component={Tips}
            exact
        />

        <Route
            path="/tips/:articleSlug"
            component={ArticleDisplay}

        />

        <PrivateRoute
            path="/insights"
            allowSelector={userSelectors.isAuthenticated}
            exact
            component={Insights}
        />
        <PrivateRoute
            path="/insights/:matchId"
            allowSelector={userSelectors.isAuthenticated}
            exact
            component={InsightMatch}
        />

        <PrivateRoute
            path="/user"
            allowSelector={userSelectors.isAuthenticated}
            component={UserProfile}
        />

        <Route path="/callback" component={AuthCallback} />
        <PrivateRoute
            path="/steamCallback"
            allowSelector={userSelectors.isAuthenticated}
            component={SteamCallback}
        />
        <Route component={NotFound} />
    </Switch>
)

export { AppRouter };