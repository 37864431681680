let driftLib;

const init = () => {
    (function() {
        if (
            ((driftLib = window.driftt = window.drift = window.driftt || []),
            !driftLib.init)
        )
            return driftLib.invoked
                ? void (
                      window.console &&
                      console.error &&
                      console.error("Drift snippet included twice.")
                  )
                : ((driftLib.invoked = !0),
                  (driftLib.methods = [
                      "identify",
                      "config",
                      "track",
                      "reset",
                      "debug",
                      "show",
                      "ping",
                      "page",
                      "hide",
                      "off",
                      "on"
                  ]),
                  (driftLib.factory = function(e) {
                      return function() {
                          var n;
                          return (
                              (n = Array.prototype.slice.call(arguments)),
                              n.unshift(e),
                              driftLib.push(n),
                              driftLib
                          );
                      };
                  }),
                  driftLib.methods.forEach(function(e) {
                      driftLib[e] = driftLib.factory(e);
                  }),
                  (driftLib.load = function(t) {
                      var e, n, o, i;
                      e = 3e5;
                      i = Math.ceil(new Date() / e) * e;
                      o = document.createElement("script");
                      o.type = "text/javascript";
                      o.async = !0;
                      o.crossorigin = "anonymous";
                      o.src =
                          "https://js.driftt.com/include/" +
                          i +
                          "/" +
                          t +
                          ".js";
                      n = document.getElementsByTagName("script")[0];
                      n.parentNode.insertBefore(o, n);
                  }));
    })();
};

const load = () => {
    driftLib.SNIPPET_VERSION = "0.3.1";
    driftLib.load("ey3nimd4s2wx");
};

export { init, load };
