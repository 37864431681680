import React, { Component } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { strategyActions, strategySelectors } from "../../Strategy/data";
import { games, getGame } from "../../games";
import { UserMapOptionsDrawer } from "../ToolDrawers/UserMapOptionsDrawer";

const content = {
    gameTitle: "Games",
    changeGame: "Change Game",
    mapTitle: "Maps",
    changeMap: "Change Map",
    tools: "Tools",
    resetStrategy: "Reset Strategy"
};

class _MapToolbox extends Component {
    renderGameDropdown() {
        const { game } = this.props;

        return games.map((gameObj, index) => {
            return (
                <button
                    key={index}
                    className={cx("dropdown-item", {
                        disabled: game === gameObj.id
                    })}
                    type="button"
                    onClick={this.props.setGame.bind(this, gameObj.id)}
                >
                    {gameObj.name}
                </button>
            );
        });
    }

    renderMapDropdown() {
        const { game, map } = this.props;
        const maps = getGame(game).maps;

        return maps.map((mapObj, index) => {
            return (
                <button
                    key={index}
                    className={cx("dropdown-item", {
                        disabled: map === mapObj.id
                    })}
                    type="button"
                    onClick={this.props.setMap.bind(this, game, mapObj.id)}
                >
                    {!!mapObj.name ? mapObj.name : mapObj.id}
                </button>
            );
        });
    }

    render() {
        return (
            <div id="map-settings" className="tool-category">
                <div className="py-2">
                    <h3>{content.gameTitle}</h3>
                    <div className="dropdown w-100">
                        <button
                            className="btn btn-primary dropdown-toggle w-100"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {content.changeGame}
                        </button>
                        <div
                            className="dropdown-menu w-100"
                            aria-labelledby="dropdownMenu2"
                        >
                            {this.renderGameDropdown()}
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <h3>{content.mapTitle}</h3>
                    <div className="dropdown w-100">
                        <button
                            className="btn btn-primary dropdown-toggle w-100"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {content.changeMap}
                        </button>
                        <div
                            className="dropdown-menu w-100"
                            aria-labelledby="dropdownMenu2"
                        >
                            {this.renderMapDropdown()}
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <h3>{content.tools}</h3>
                    <button
                        className="btn btn-warning btn-md btn-default w-100"
                        onClick={this.props.resetMap}
                    >
                        {content.resetStrategy}
                    </button>
                </div>
                <UserMapOptionsDrawer />
                {/* <CollaborationDrawer /> */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    game: strategySelectors.game(state),
    map: strategySelectors.map(state)
});

const mapDispatchToProps = dispatch => ({
    setGame: gameId => dispatch(strategyActions.setGame(gameId)),
    setMap: (gameId, mapId) => dispatch(strategyActions.setMap(gameId, mapId)),
    resetMap: () => dispatch(strategyActions.resetMap())
});

const connected = connect(mapStateToProps, mapDispatchToProps)(_MapToolbox);

export { _MapToolbox, connected as MapToolbox };
