import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";

import { toolActions, toolColors, toolSelectors } from "./data";

const _ToolButton = ({
    children,
    toolColor,
    toolName,
    setTool,
    currentTool
}) => (
        <button
            type="button"
            onClick={setTool.bind(this, toolName)}
            className={cx("btn btn-md btn-default btn-tool", {
                active: currentTool === toolName
            })}
            style={{ backgroundColor: toolColor || toolColors[toolName] }}
        >
            {children}
        </button>
    );

_ToolButton.propTypes = {
    children: PropTypes.node.isRequired,
    toolColor: PropTypes.string,
    setTool: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    currentTool: toolSelectors.currentTool(state)
});

const mapDispatchToProps = dispatch => ({
    setTool: toolName => dispatch(toolActions.setTool(toolName))
});

const ToolButton = connect(mapStateToProps, mapDispatchToProps)(_ToolButton);

export { _ToolButton, ToolButton };
