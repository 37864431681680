import { combineReducers } from "redux";

const toolColors = {
    "player-blue": "#6CA0DC",
    "player-yellow": "#FFFF00",
    "player-purple": "#800080",
    "player-green": "#00A550",
    "player-orange": "#FF5800",
    "grenade-smoke": "#aaaaaa",
    "grenade-flash": "#D6C237",
    "grenade-he": "#D66D37",
    "grenade-fire": "#FF0000",
    "grenade-decoy": "#FFbb00",
    "grenade-recon": "#FFbb00",

    "agent-brimstone": "#6b8499",
    "agent-breach": "#d9743c",
    "agent-cypher": "#bbc1bc",
    "agent-jett": "#649ddc",
    "agent-omen": "#3b3871",
    "agent-phoenix": "#fffa7b",
    "agent-sage": "#0f3441",
    "agent-sova": "#3e5c89",
    "agent-raze": "#c68e42",
    "agent-viper": "#296e32",
    circletext: "#000000",
    inversecircletext: "#111111"
};

const TOOL_CHANGE = "ge:TOOL_CHANGE";

const initialState = {
    currentTool: ""
};

const toolReducers = (function () {
    const currentTool = (state = initialState.currentTool, action) => {
        if (action.type === TOOL_CHANGE) {
            if (action.payload === state) {
                return initialState.currentTool;
            }
            return action.payload;
        }
        return state;
    };

    return combineReducers({
        currentTool
    });
})();

const toolActions = (function () {
    const setTool = toolName => ({
        type: TOOL_CHANGE,
        payload: toolName
    });

    const clearTool = () => ({
        type: TOOL_CHANGE,
        payload: initialState.currentTool
    });
    return {
        clearTool,
        setTool
    };
})();

const toolSelectors = (function () {
    const tools = state => state.planner.tools;
    const currentTool = state => tools(state).currentTool;

    return {
        tools,
        currentTool
    };
})();

export { toolActions, toolReducers, toolSelectors, toolColors };
