import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { mapMap, insightsSelectors } from "./data";
import { HltvScoreDisplay } from "./HltvScoreDisplay";

const cardStyle = () => ({
    backgroundColor: "#333"
});

const _InsightsRow = ({ match, insights }) => {

    var insight = insights && insights[0];
    return (
        <div className="col-4 mb-3">
            <div className="card match" style={cardStyle()}>
                <img className="card-img-top w-100" src={"/images/insights/csgo/" + match.map + ".jpg"} />
                <div className="card-body">
                    <h5 className="card-title mb-0">
                        {mapMap[match.map]}
                    </h5>
                    <h5>
                        <small className="text-muted">
                            (Played: {new Date(match.addedAt).toLocaleDateString()})
                        </small>
                    </h5>

                    <HighLevelDisplay insight={insight} />
                    <Link className="btn btn-outline-primary" to={"/insights/" + match.id}>View insights</Link>
                </div>
            </div>
        </div>
    );
};

const HighLevelDisplay = ({ insight }) => {
    return (
        <p className="card-text row">
            <div className="col-md-6 col-12">
                <div className="mb-2">
                    <small>Total Kills: </small><br />
                    <span>{insight ? insight.TotalKills : ''}</span>
                </div>
                <div className="mb-2">
                    <small>Total Deaths: </small><br />
                    <span>{insight ? insight.TotalDeaths : ''}</span>
                </div>
            </div>
            <div className="col-md-6 col-12">
                <div className="mb-2">
                    <HltvScoreDisplay hltvScore={insight ? insight.HltvScore : null} />
                </div>
                <div className="mb-2">
                    <small>Source: </small><br />
                    <i className="fab fa-steam-square fa-2x"></i>
                </div>
            </div>
        </p>
    )
}

const mapStateToProps = (state, props) => ({
    insights: insightsSelectors.matchInsightByMatchId(state, props.match.id)
})

const connected = connect(mapStateToProps)(_InsightsRow)

export { _InsightsRow, connected as InsightsRow };
