import React from "react";
import { connect } from "react-redux";

import { OverallStats } from "./Overallstats";
import { UtilityStats } from "./Utilitystats";
import { insightsSelectors } from "../data";
import { mapMap } from "../data";
import { PanelContainer } from "./PanelContainer";

const _InsightMatch = ({ match, insight }) => {
    return (
        <div className="container">
            <div className="row pt-3 pb-3">
                <div className="col-6">
                    <h5 className="mb-3">Played on {new Date(match.addedAt).toLocaleDateString()}</h5>
                    <OverallStats insight={insight} />
                </div>
                <div className="col-6">
                    <h3 className="">{mapMap[match.map]}</h3>
                    <img className="w-100" src={"/images/insights/csgo/" + match.map + ".jpg"} />
                </div>
            </div>
            <PanelContainer match={match} insight={insight} />
        </div>
    );
};

const mapStateToProps = (state, props) => ({
    match: insightsSelectors.matchByMatchId(state, props.match.params.matchId)[0],
    insight: insightsSelectors.matchInsightByMatchId(state, props.match.params.matchId)[0]
});

const connected = connect(mapStateToProps)(_InsightMatch);

export { _InsightMatch, connected as InsightMatch };
