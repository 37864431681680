import React, { Component } from "react";
import { connect } from "react-redux";

import { insightsActions } from "./data";
import { InsightsContainer } from "./InsightsTableContainer";

const _Insights = ({ matches, matchesLoad }) => {
    if (matches === null) {
        matchesLoad();
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h3>Insights</h3>
                </div>
                <InsightsContainer />
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    matches: state.insights.matches
});

const mapDispatchToProps = dispatch => ({
    matchesLoad: () => dispatch(insightsActions.matchesLoad())
});

const connected = connect(mapStateToProps, mapDispatchToProps)(_Insights);

export { _Insights, connected as Insights };
