import { combineReducers } from "redux";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";
import { notify } from "react-notify-toast";

import { strategyActions, strategySelectors } from "./Strategy/data";
import { toolActions, toolReducers } from "./Tools/data";
import { strategyReducers } from "./Strategy/data";
import { userSelectors } from "../Auth/data";
import { callUnprotectedApi, callProtectedApi } from "../../Utils/apiService";
import { init } from "../../Utils/vendor/drift";
import { collaborationActions } from "../../Utils/collaboration";

//Consts
const PLANNER_GET_STRATEGY_ID = "planner:GET_STRATEGY_ID";
const PLANNER_SAVE_STRATEGY = "planner:PLANNER_SAVE_STRATEGY";
const PLANNER_LOAD = "planner:PLANNER_LOAD";
const VIEWER_LOAD = "viewer:VIEWER_LOAD";

// Actions
const plannerActions = {
    getStrategy: strategyId => ({
        type: PLANNER_GET_STRATEGY_ID,
        payload: strategyId
    }),
    saveStrategy: () => ({
        type: PLANNER_SAVE_STRATEGY
    }),
    plannerLoad: payload => ({
        type: PLANNER_LOAD,
        payload
    }),
    viewerLoad: payload => ({
        type: VIEWER_LOAD,
        payload
    })
};

//Sagas
function* getStrategyById(action) {
    const strategyId = action.payload;

    try {
        const response = yield call(
            callUnprotectedApi,
            "get",
            `strategy/${strategyId}`
        );

        const strategy = response.data;

        yield* SetStoreStrategy(strategy);

        yield put(toolActions.clearTool());

        notify.show("Loaded strategy", "success");
    } catch (e) {
        console.log("Couldn't load strategy");

        notify.show("Error loading strategy", "error");
    }
}

function* SetStoreStrategy(strategy) {
    if (strategy.id) {
        yield put(strategyActions.setId(strategy.id));
    }
    yield put(strategyActions.setGame(strategy.game));
    yield put(strategyActions.setMap(strategy.game, strategy.map));
    yield put(strategyActions.setPoints(strategy.points));
    yield put(strategyActions.setNotes(strategy.notes));
}

function* checkUrl(action) {
    let strategyId = action.payload;
    if (!action.payload) {
        const location = yield select(state => state.router.location);
        const params = new URLSearchParams(location.search);

        strategyId = params.get("strategyId");

        const sessionId = params.get("cSessionId");
        if (sessionId) {
            yield put(collaborationActions.joinSessionRequest(sessionId));
        }
    }

    yield put(plannerActions.viewerLoad(strategyId));
}

function* setStrategy(action) {
    yield put(strategyActions.resetMap());
    yield put(toolActions.clearTool());

    const strategyId = action.payload;

    if (!!strategyId) {
        yield put(plannerActions.getStrategy(strategyId));
    }
}

function* saveStrategy() {
    const strategy = yield select(strategySelectors.strategy);

    try {
        const accessToken = yield select(userSelectors.accessToken);

        const response = yield call(
            callProtectedApi,
            "post",
            "strategy",
            accessToken,
            strategy
        );
        const newId = response.data.id;
        if (!!newId) {
            yield put(push(`/planner/${newId}`));

            notify.show("Saved strategy", "success");
        }
    } catch (e) {
        console.log("Error saving strategy " + e);

        notify.show("Error saving strategy", "error");
    }
}

function* PlannerSaga() {
    yield all([
        yield takeEvery(PLANNER_GET_STRATEGY_ID, getStrategyById),
        yield takeEvery(PLANNER_LOAD, checkUrl),
        yield takeEvery(PLANNER_SAVE_STRATEGY, saveStrategy),
        yield takeEvery(VIEWER_LOAD, setStrategy)
    ]);
}

//Reducers
const PlannerReducer = combineReducers({
    strategy: strategyReducers,
    tools: toolReducers
});

export { PlannerSaga, PlannerReducer, plannerActions, SetStoreStrategy };
