import React from "react";
import { withRouter, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const _PrivateRoute = ({ component: Component, allow, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            allow === true ? <Component {...props} /> : <Redirect to="/" />
        }
    />
);

_PrivateRoute.propTypes = {
    allow: PropTypes.bool.isRequired,
    component: PropTypes.any.isRequired
};

const mapStateToProps = (state, props) => ({
    allow: props.allowSelector(state)
});

const connected = withRouter(connect(mapStateToProps)(_PrivateRoute));

connected.propTypes = {
    path: PropTypes.string.isRequired,
    allowSelector: PropTypes.func.isRequired,
    component: PropTypes.any.isRequired
};

export { _PrivateRoute, connected as PrivateRoute };
